.go-to-homepage {
  cursor: default;
  animation: show-background 0.3s forwards ease-out;

  &__background {
    position: fixed;
    top: 0;
    left: 128px;
    width: calc(100% - 128px);
    height: 100%;
    //backdrop-filter: blur(25px);
    background: rgba(0, 0, 0, 0.5);

    @media ($desktop-2560) {
      left: 160px;
      width: calc(100% - 160px);
    }
  }

  &__full-size {

    & .go-to-homepage__background {
      left: 0;
      width: 100%;
    }
  }
}
