.properties {
  &__button {
    @include bold;
    border: none;
    cursor: pointer;
    background-size: 25px 25px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    color: $white;
    padding: 12px 12px 12px 50px;
    font-size: 11px;
    position: relative;


    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      left: 17px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../images/table-current-prop-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);
    opacity: 0.75;

    animation: show-background 0.3s forwards ease-out;
  }

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 300px;
    z-index: 5;
    margin-left: -150px;
    margin-top: -132px;
    background-color: $dark-grey;
    border-radius: 8px;
    animation: show-language-select 0.3s forwards ease-out;
    overflow: hidden;
  }

  &__modal-title {
    padding: 20px 16px;
    margin: 0;
    @include bold;
    font-size: 20px;
    line-height: 27px;
  }

  &__item {
    padding: 16px 42px;
    cursor: pointer;
    transition-duration: 300ms;
    position: relative;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:last-child {
      border-bottom: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -6px;
      left: 16px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    &:nth-child(1):before {
      background-color: $cyan;
    }

    &:nth-child(2):before {
      background-color: $yellow;
    }

    &:nth-child(3):before {
      background-color: $red;
    }

    &:nth-child(4):before {
      background-color: $green;
    }
  }
}

@media ($desktop-1440) {

  .properties {

    &__button {
      font-size: 14px;
    }
  }
}

@media ($desktop-1920) {

  .properties {

    &__button {
      font-size: 16px;
      padding: 17px 17px 17px 50px;
    }
  }
}
