.search {
  position: fixed;
  top: 0;
  left: 128px;

  width: 350px;
  height: 100%;
  background-color: $black;

  animation: show-left-window 0.3s forwards ease-out;

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 20px;
    padding-right: 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    padding-bottom: 18px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 20px;
      background: linear-gradient(180deg, #21262C 0%, rgba(33, 38, 44, 0) 100%);
    }
  }

  &__form {
    position: relative;
  }

  &__input {
    @include bold;
    width: 100%;
    padding: 15px 84px 15px 15px;
    border-radius: 8px;
    font-size: 17px;
    line-height: 17px;
    color: $white;

    background-color: $dark-grey;
    border: none;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    outline: none;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);;
    }
  }

  &__reset-button {
    position: absolute;
    top: 50%;
    right: 45px;

    width: 32px;
    height: 32px;
    margin-top: -16px;

    background: url('../../images/close-icon.svg') no-repeat center;
    background-size: 14px 14px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__filters-button {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    border: none;
    cursor: pointer;
    background: url('../../images/filters-icon.svg') no-repeat center 5px;
    background-size: 16px 18px;
  }

  &__filters-indicator {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $green;
    transform: translateY(5px);

    &--name {
      background-color: $dark-red;
    }

    &--symbol {
      background-color: $blue;
    }

    &--number {
      background-color: $orange;
    }

    &--year {
      background-color: $light-green;
    }

    &--weight {
      background-color: $cyan;
    }

    &--density {
      background-color: #725AC1;
    }

    &--electro {
      background-color: #e84e40;
    }

    &--cas {
      background-color: #f5be25;
    }

    &--electronegativity {
      background-color: #129490;
    }

    &--radius {
      background-color: #8D86C9;
    }

    &--electrons {
      background-color: #0a7f42;
    }
  }

  &__parameters {
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 8px;
    animation: show-language-select 0.3s forwards ease-out;
    background-color: $dark-grey;
    padding: 20px;
    z-index: 2;
  }

  &__parameters-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__parameter {
    width: calc(50% - 5px);
    height: 45px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      color: $white;
      text-align: left;
      cursor: pointer;
      font-size: 14px;
      background: no-repeat 8px center;
      background-size: 24px 24px;
      display: flex;
      align-items: center;
      padding: 5px 5px 5px 40px;
      border-radius: 8px;
      transition-duration: 200ms;
      word-break: break-all;

      &:hover,
      &:focus {
        background-color: $light-grey;
      }
    }


    &--all {
      button {
        background-image: url('../../images/filters/all-filter-icon.svg');
      }
    }

    &--name {
      button {
        background-image: url('../../images/filters/name-filter-icon.svg');
      }
    }

    &--symbol {
      button {
        background-image: url('../../images/filters/symbol-filter-icon.svg');
      }
    }

    &--number {
      button {
        background-image: url('../../images/filters/number-filter-icon.svg');
      }
    }

    &--year {
      button {
        background-image: url('../../images/filters/year-filter-icon.svg');
      }
    }

    &--weight {
      button {
        background-image: url('../../images/filters/weight-filter-icon.svg');
      }
    }

    &--density {

      button {
        background-image: url('../../images/filters/plot-filter-icon.svg');
      }
    }


    &--electro {

      button {
        background-image: url('../../images/filters/electro-filter-icon.svg');
      }
    }

    &--cas {

      button {
        background-image: url('../../images/filters/cas-filter-icon.svg');
      }
    }

    &--electronegativity {

      button {
        background-image: url('../../images/filters/electronegativity-filter-icon.svg');
      }
    }

    &--radius {

      button {
        background-image: url('../../images/filters/radius-filter-icon.svg');
      }
    }

    &--electrons {

      button {
        background-image: url('../../images/filters/electrons-filter-icon.svg');
      }
    }

    &--disabled {

      button {
        background-image: url('../../images/filters/locked-filter-icon.svg');
        color: rgba($white, 0.6);
      }
    }
  }

  &__results-header {
    position: relative;

    width: 100%;
    margin: -1px 0 0;
    padding: 15px 48px;

    font-size: 13px;

    background-color: $black;
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;

    @include bold;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 15px;

      width: 20px;
      height: 18px;
      margin-top: -9px;

      background-image: url('../../images/atom-icon.svg');
      background-repeat: no-repeat;
      background-size: 20px 18px;
    }
  }

  &__elements-list {
    height: 100%;
    overflow: auto;
    padding-bottom: 120px;

    & li:last-child {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 76px;
      }
    }
  }

  &__elements-item {
    display: flex;
    align-items: center;

    color: $white;
    text-decoration: none;
    padding: 16px;
    transition-duration: 0.4s;
    border-radius: 8px;

    &:hover {
      background-color: $light-grey;
    }
  }

  &__element-name-and-mass {
    display: flex;
    flex-direction: column;
  }

  &__element-name {
    @include bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2px;
  }

  &__element-mass {
    font-size: 14px;
    text-align: left;
  }

  &__not-found {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -72px;

    &-text {
      font-size: 16px;
      @include bold;
      text-align: center;
      margin: 15px 0 0;
    }
  }
}

@media ($desktop-2560) {
  .search {
    left: 160px;
  }
}
