.table-1 {

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: show-language-select 0.3s forwards ease-out;

  &__modal {
    display: flex;
    gap: 20px;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
  }

  &__modal-wrapper {
    padding: 15px 0 15px 15px;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    gap: 10px;
  }

  &__title {
    margin: 0;
    font-size: 20px;
    flex-grow: 1;
    @include bold;
  }

  &__close-button {
    display: inline-block;
    width: 44px;
    height: 44px;

    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
    transform: rotate(180deg);
  }

  &__reset-button {
    width: 18px;
    height: 18px;
    background: url('../../images/close-icon.svg') no-repeat center;;
    background-size: 100%;
    border: none;
    cursor: pointer;
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__table-wrapper {
    display: flex;
  }

  &__top {
    display: flex;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 14px;
    gap: 2px;
    padding: 4px;
  }

  &__left {
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 2px 3px;
    gap: 2px;
    margin-right: 14px;
  }

  &__table {
    margin-right: 1px;

    border-collapse: separate;
    text-align: center;

    @include bold;
  }

  &__table-header {
    font-size: 11px;
    @include bold;
    width: 38px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--top {
      width: 32px;

      & sup {
        color: $red;
      }
    }

    &--left {
      & sup {
        color: $cyan;
      }
    }
  }

  &__table-cell {
    width: 32px;
    height: 30px;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.1);
    color: $silver;
    border-radius: 3px;
    transition-duration: 300ms;

    &--soluble {
      color: #34b047;
      background: rgba(83, 165, 72, 0.25);
    }

    &--slightly-soluble {
      color: $yellow;
      background: rgba(247, 184, 1, 0.25);
    }

    &--insoluble {
      color: $red;
      background: rgba(219, 58, 52, 0.2);
    }

    &--decomposed {
      color: $cyan;
      background: rgba(32, 164, 243, 0.25);
    }
  }

  &__legend {
    background: #1A1E22;
    border-left: 1px solid #31343D;
    border-radius: 0px 10px 10px 0px;
    padding: 0 15px;
  }

  &__legend-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
    height: 100%;
  }

  &__legend-item {
    min-width: 80px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: #22282E;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }

  &__legend-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    color: $silver;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.1);;
    transition-duration: 300ms;

    @include bold;

    &--soluble {
      color: #34b047;
      background: rgba(83, 165, 72, 0.25);
    }

    &--slightly-soluble {
      color: $yellow;
      background: rgba(247, 184, 1, 0.25);
    }

    &--insoluble {
      color: $red;
      background: rgba(219, 58, 52, 0.2);
    }

    &--decomposed {
      color: $cyan;
      background: rgba(32, 164, 243, 0.25);
    }
  }

  &__legend-name {
    font-size: 11px;
    text-align: center;
    @include bold;
  }
}

@media ($desktop-2560) {
  .table-1 {

    &__table-header {
      font-size: 14px;

      &--top {
        width: 47px;
        height: 45px;
      }

      &--left {
        width: 47px;
        height: 45px;
      }
    }

    &__table-cell {
      width: 47px;
      height: 45px;
      font-size: 15px;
    }

    &__legend-name {
      font-size: 13px;
    }
  }
}