.settings {
  position: fixed;
  top: 0;
  left: 128px;

  width: 350px;
  height: 100%;
  background-color: $black;
  border-right: 1px solid $light-grey;

  animation: show-left-window 0.3s forwards ease-out;

  &__container {
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding-top: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    margin: 0 0 20px;
    padding: 20px 15px;

    font-size: 28px;

    @include bold;
  }

  &__section-title {
    font-size: 20px;
    padding-left: 67px;
    position: relative;
    margin: 0 0 12px;

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 34px;
      margin-top: -4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #ce2d4f;
      box-shadow: 0px 0px 0px 3px #20252B, 0px 0px 0px 5px #CE2D4F;
      margin-bottom: 16px;
    }

    &--extra::before {
      background-color: #717886;
      box-shadow: 0px 0px 0px 3px #20252B, 0px 0px 0px 5px #717886;
    }

    &--images::before {
      background-color: #FCBA04;
      box-shadow: 0px 0px 0px 3px #20252B, 0px 0px 0px 5px #FCBA04;
    }

    &--about::before {
      background-color: #3066BE;
      box-shadow: 0px 0px 0px 3px #20252B, 0px 0px 0px 5px #3066BE;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 10px 34px;
    margin-bottom: 34px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 10px;
      width: calc(100% - 10px);
      height: 2px;
      border-radius: 4px;
      background-color: $dark-grey;
    }

    &:last-child::after {
      display: none;
    }
  }

  &__item {
    &--font-size {
      padding: 10px 20px 10px 58px;
      position: relative;
      border-radius: 8px;

      &::before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        left: 16px;
        top: 50%;
        margin-top: -12px;
        background-position: center;
        background-size: 100%;
        background-image: url('../../images/font-size-icon.svg');
      }
    }

    &--range {
      padding: 10px 20px 10px 58px;
    }
  }

  &__item-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $white;
    padding: 10px 20px 10px 58px;
    position: relative;
    border-radius: 8px;
    transition: 300ms ease-in-out;

    &:hover {
      background-color: $light-grey;
    }

    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      left: 16px;
      top: 50%;
      margin-top: -12px;
      background-position: center;
      background-size: 100%;
    }

    &--language::before {
      background-image: url('../../images/language-icon.svg');
    }

    &--report::before {
      background-image: url('../../images/report-icon.svg');
    }

    &--android::before {
      background-image: url('../../images/android-app-icon.svg');
    }

    &--ios::before {
      background-image: url('../../images/ios-app-icon.svg');
    }

    &--image-elements::before {
      background-image: url('../../images/images-icon.svg');
    }

    &--emission-spectrum::before {
      background-image: url('../../images/spectrum-icon.svg');
    }

    &--about::before {
      background-image: url('../../images/about-icon.svg');
    }

    &--version::before {
      background-image: url('../../images/periodic-table-icon.svg');
    }

    &--logout {
      @include bold;
      justify-content: center;
      border: none;
      width: 100%;
      min-height: 63px;
      color: $red;
      background: rgba($red, 0.15);
      cursor: pointer;

      &::before {
        background-image: url('../../images/logout-red.svg');
      }

      &:hover {
        background-color: $red;
        color: $white;

        &::before {
          background-image: url('../../images/logout.svg');
        }
      }
    }
  }

  &__item-title {
    @include bold;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 5px;
  }

  &__item-description {
    margin: 0;
    font-size: 14px;
    line-height: 19px;
  }

  &__range-wrapper {
    width: 100%;
  }

  &__range-input {
    width: 100%;
    -webkit-appearance: none;
    height: 2px;
    background: #5F6265;
    border-radius: 4px;
    background-image: linear-gradient(#53A548, #53A548);
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      background: #53A548;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

      &:hover {
        box-shadow: #53A54850 0px 0px 0px 8px;
      }

      &:active {
        box-shadow: #53A54850 0px 0px 0px 11px;
        transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }

    &::-moz-range-thumb {
      -moz-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      background: #53A548;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

      &:hover {
        box-shadow: #53A54850 0px 0px 0px 8px;
      }

      &:active {
        box-shadow: #53A54850 0px 0px 0px 11px;
        transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }


    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  &__range-values {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    position: relative;
    margin-top: 18px;
  }

  &__range-value {
    &--normal {
      position: absolute;
      width: 100%;
      text-align: center;
    }
  }
}

@media ($desktop-2560) {
  .settings {
    left: 160px;
  }
}
