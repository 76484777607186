.compare {
  position: fixed;
  top: 0;
  right: 0;
  width: 33%;
  max-width: 721px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #1C1F26;
  border-left: 1px solid $light-grey;

  animation: show-right-window 200ms forwards ease-out;

  &__loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__container {
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__header {
    position: relative;

    &-dark-gradient {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(46, 46, 46, 0) 0%, rgba(0, 0, 0, 0.8) 100%) 99%;
      width: 100%;
      height: calc(100% - 2px);
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 24px 50px;
    }
  }

  &__elements-images {
    display: flex;
    align-items: center;
  }

  &__element-image-wrapper {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));


    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:last-child {
      margin-left: -5px;
    }
  }

  &__elements-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  &__element-info {
    flex: 1;
    display: flex;
    flex-direction: column;

    .read-element__selected-element {
      padding-top: 14px;
      padding-bottom: 20px;
    }

    &:first-child {

      & .read-element__groups {
        align-items: flex-start;
      }

      & .read-element__selected-element {
        padding-left: 23px;
      }
    }

    &:last-child {

      & .read-element__selected-element {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-right: 23px;

        .read-element__name {
          text-align: right;
        }


        .read-element__symbol-wrapper {
          justify-content: flex-end;
          gap: 16px;

          .read-element__symbol {
            order: 1;
            margin-right: 0;
          }
        }

        .read-element__weight {
          text-align: right;
        }
      }
    }
  }

  &__vs-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 4px;
    color: #1C1F26;
    background-color: $white;
    font-weight: 800;
  }

  &__modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: show-language-select 0.3s forwards ease-out;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
    width: 412px;
    height: calc(100vh - 100px);
  }

  &__modal-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 24px 10px 10px;
  }

  &__input-wrapper {
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 24px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: calc(100% + 24px);
      left: 0;
      width: 100%;
      height: 20px;
      background: linear-gradient(180deg, #21262C 0%, rgba(33, 38, 44, 0) 100%);
    }
  }

  &__modal-title {
    margin: 0;

    h3 {
      font-size: 21px;
      @include bold;
      margin: 0 0 4px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }

  &__modal-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
  }

  &__modal-element-item {
    border: none;
    background: none;
    cursor: pointer;

    & .search__element-name {
      text-align: left;
    }
  }

  &__props-wrapper {
    display: flex;
    padding: 7px 0;
    border-bottom: 1px solid #272A31;

    & .compare__props-item:nth-child(1) {
      width: 120px;
      display: flex;
      flex: 1;
      align-items: center;
      padding: 8px 20px;
    }

    & .compare__props-item:nth-child(2),
    & .compare__props-item:nth-child(3) {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 16px;
      width: 120px;
      text-align: center;
    }


    & .compare__props-item:nth-child(2) {
      border-left: 1px solid #272A31;
      border-right: 1px solid #272A31;
    }
  }

  &__props-item {
    font-size: 13px;
    white-space: pre-wrap; /* Сохраняет пробелы и переносит строки */
    word-wrap: break-word; /* Переносит длинные слова */
    overflow-wrap: break-word; /* Перенос длинных слов в современных браузерах */
    word-break: break-word;

    &--empty {
      opacity: 0.4;
    }

    &-color {
      display: inline-block;
      width: 2px;
      height: 24px;
      margin-right: 10px;
    }
  }

  &__props-over {
    padding: 3px !important;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: #272A31;
      border-radius: 6px;
      padding: 10px;

      & > span {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        word-wrap: break-word;
      }
    }
  }

  &__spectrum-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 20px;
  }

  &__spectrum-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__electron-shell {
    border-bottom: 1px solid #272A31;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;

    &--first {
      margin-left: -24px;
    }

    &--second {
      margin-right: -30px;
    }
  }

  &__electron-shell-value-wrapper {
    padding: 0 15px;
    font-size: 15px;

    p {
      margin: 0;
    }
  }

  &__anc-table {
    padding: 8px 32px;

    .read-element__anc-table-value {
      color: $white;
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__grids-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 24px;
  }

  &__cubes-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 48px 0;
  }
}

@media ($desktop-1360) {

  .compare {

    &__props-item {
      font-size: 14px;
    }
  }
}

@media ($desktop-1440) {

  .compare {
    &__cubes-wrapper {

      .read-element__diamond-symbol {
        width: 42px;
        height: 42px;
        font-size: 19px;
      }
    }
  }
}

@media ($desktop-1680) {
  .compare {

    &__props-wrapper {

      & .compare__props-item:nth-child(1) {
        width: 120px;
        padding: 12px 20px;
      }

      & .compare__props-item:nth-child(2),
      & .compare__props-item:nth-child(3) {
        padding: 15px 5px;
        width: 160px;
      }
    }

    &__props-item {
      font-size: 16px;
    }

    &__props-over {
      & > div {
        padding: 15px 8px;
      }
    }

    &__electron-shell {

      &--first {
        margin-left: 0;
      }

      &--second {
        margin-right: 0;
      }
    }

    &__electron-shell-value-wrapper {
      padding: 0 30px;
      font-size: 17px;
    }

    &__anc-table {
      padding: 8px 32px;

      .read-element__anc-table-value {
        font-size: 32px;
        line-height: 54px;
      }
    }
  }
}

@media ($desktop-1920) {
  .compare {

    &__props-wrapper {

      & .compare__props-item:nth-child(2),
      & .compare__props-item:nth-child(3) {
        width: 190px;
      }
    }
  }
}

@media ($desktop-2560) {
  .compare {

    &__props-wrapper {

      & .compare__props-item:nth-child(2),
      & .compare__props-item:nth-child(3) {
        width: 210px;
      }
    }
  }
}