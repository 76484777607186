.element-list {
  position: fixed;
  top: 0;
  left: 128px;

  width: 350px;
  height: 100%;
  background-color: $black;
  animation: show-left-window 0.3s forwards ease-out;

  &__container {
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding-top: 25px;
    padding-right: 15px;
    padding-left: 15px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__category-marker {
    display: inline-block;
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    //margin-right: 17px;
  }

  &__settings-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 20px;
      background: linear-gradient(180deg, #21262C 0%, rgba(33, 38, 44, 0) 100%);
      z-index: 3;
    }
  }

  &__sorting-btn {
    min-width: 32px;
    width: 32px;
    height: 32px;
    border: none;
    background: url('../../images/sort-icon.svg') no-repeat center;
    background-size: 24px 24px;
    margin-left: 20px;
    cursor: pointer;

    &--default {
      background: url('../../images/sort-icon.svg') no-repeat center;
    }

    &--ascending {
      background: url('../../images/sort-ascending-icon.svg') no-repeat center;
    }

    &--descending {
      background: url('../../images/sort-descending-icon.svg') no-repeat center;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 7px;
    height: 100%;
    overflow: auto;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 49px;
    color: $white;
    text-decoration: none;
    transition-duration: 0.3s;

    &:hover .element-list__prop-wrapper {
      background-color: $light-grey;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 14px;

      display: inline-block;
      width: 9px;
      height: 14px;
      margin-top: -7px;

      background: url("../../images/arrow-right.svg") no-repeat center;
      background-size: 100%;
    }
  }

  &__number {
    @include roboto-medium;
    font-size: 11px;
    line-height: 11px;
  }

  &__symbol {
    @include bold;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 1px;
  }

  &__name {
    display: inline-block;
    width: 55px;
    height: 17px;
    overflow: hidden;

    font-size: 11px;
    line-height: 11px;
    @include roboto-regular;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__prop-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 90%;
    margin-left: 3px;
    padding-left: 12px;
    border-radius: 5px;
    background-color: #2B3036;
  }

  &__prop {
    position: relative;
    z-index: 2;
    font-size: 17px;
    @include bold;

    &--small-font {
      font-size: 12px;
    }
  }
}

@media (min-width: $desktop-1360) {

  .element-list {

    &__item {
      height: 53px;
    }

    &__number {
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    &__name {
      font-size: 11px;
      line-height: 11px;
    }
  }
}

@media (min-width: $desktop-1440) {

  .element-list {

    &__item {
      height: 56px;
    }

    &__number {
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 4px;
    }

    &__symbol {
      font-size: 21px;
      line-height: 21px;
      margin-bottom: 3px;
    }

    &__name {
      margin-top: -1px;
      font-size: 11px;
      line-height: 11px;
    }
  }
}

@media ($desktop-1680) {

  .element-list {

    &__item {
      height: 71px;
    }

    &__number {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 5px;
    }

    &__name {
      font-size: 13px;
      line-height: 13px;
      margin: 0;
    }
  }
}

@media ($desktop-1920) {

  .element-list {

    &__item {
      height: 75px;
    }

    &__number {
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 3px;
    }

    &__name {
      font-size: 15px;
      line-height: 15px;
    }
  }
}
