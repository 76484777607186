.table-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: show-language-select 0.3s forwards ease-out;

  &__modal {
    min-width: 830px;
    width: 830px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
    padding: 20px;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 25px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
  }

  &__title {
    margin: 0;
    font-size: 20px;

    @include bold;
  }

  &__close-button {
    display: inline-block;
    width: 44px;
    height: 44px;

    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
    transform: rotate(180deg);
  }

  &__card {
    width: 50%;
    height: 260px;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
  }

  &__description-container {
    height: 100%;
    padding: 0 28px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition-duration: 300ms;
    position: relative;
    background: #22282E;

    &.table-3__description-container--0 {
      background: #3066be;
    }

    &.table-3__description-container--1 {
      background: #ce2d4f;
    }

    &.table-3__description-container--2 {
      background: #c59405;
    }

    &.table-3__description-container--3 {
      background: #9248d6;
    }
  }

  &__description-icon {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: $white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;

    &.table-3__description-icon--0 {
      background-image: url('../../images/indicator-icon-1.svg');
    }

    &.table-3__description-icon--1 {
      background-image: url('../../images/indicator-icon-2.svg');
    }

    &.table-3__description-icon--2 {
      background-image: url('../../images/indicator-icon-3.svg');
    }

    &.table-3__description-icon--3 {
      background-image: url('../../images/indicator-icon-4.svg');
    }
  }

  &__indicator-name {
    @include bold;
    font-size: 32px;
    margin-bottom: 8px;
  }

  &__indicator-description {
    font-size: 16px;
    line-height: 120%;
    @include regular;
    margin: 0;
  }

  &__formula-name {
    @include bold;
    font-size: 28px;
    margin-bottom: 6px;
    color: $white;
    transition-duration: 300ms;
  }

  &__formula {
    font-size: 16px;
  }

  &__formulas-container {

    padding: 21px 21px 21px 74px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 30px;
      left: 27px;
      width: 17px;
      height: 199px;
      background-repeat: no-repeat;
      background-size: 17px 199px;
      transition-duration: 300ms;
    }

    &.table-3__formulas-container--0 {
      &::before {
        background-image: url('../../images/color-line-1.svg');
      }

      & .table-3__formula-name--1 {
        color: $red;
      }

      & .table-3__formula-name--2 {
        color: $violet;
      }

      & .table-3__formula-name--3 {
        color: $cyan;
      }
    }

    &.table-3__formulas-container--1 {
      &::before {
        background-image: url('../../images/color-line-2.svg');
      }

      & .table-3__formula-name--3 {
        color: $red;
      }
    }

    &.table-3__formulas-container--2 {
      &::before {
        background-image: url('../../images/color-line-1.svg');
      }

      & .table-3__formula-name--1 {
        color: #CE2D8E;
      }

      & .table-3__formula-name--2 {
        color: $orange;
      }

      & .table-3__formula-name--3 {
        color: #FCBA04;
      }
    }

    &.table-3__formulas-container--3 {
      &::before {
        background-image: url('../../images/color-line-1.svg');
      }

      & .table-3__formula-name--1 {
        color: $red;
      }

      & .table-3__formula-name--2 {
        color: $light-green;
      }

      & .table-3__formula-name--3 {
        color: $violet;
      }
    }
  }

  &__indicator-select-wrapper {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    width: 100%;
  }

  &__indicator-select {
    width: calc(25% - 12px);
    border: none;
    border-radius: 10px;
    background: #22282E;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 14px 0 18px;
    cursor: pointer;
    transition-duration: 300ms;

    &--current {
      transform: translateY(-8px);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        bottom: 11px;
        left: 50%;
        transform: translateX(-50%);
      }

      path {
        fill: $white;
        transition-duration: 300ms;
      }
    }

    &--1 {

      & .table-3__indicator-select-icon {
        background: rgba(48, 102, 190, 0.15)
      }
    }

    &--1.table-3__indicator-select--current {
      & .table-3__indicator-select-icon,
      &::after {
        background: rgba(48, 102, 190, 1)
      }
    }

    &--2 {

      & .table-3__indicator-select-icon {
        background: rgba(206, 45, 79, 0.15);
      }
    }

    &--2.table-3__indicator-select--current {
      & .table-3__indicator-select-icon,
      &::after {
        background: rgba(206, 45, 79, 1);
      }
    }

    &--3 {

      & .table-3__indicator-select-icon {
        background: rgba(247, 184, 1, 0.15);
      }
    }

    &--3.table-3__indicator-select--current {
      & .table-3__indicator-select-icon,
      &::after {
        background: rgba(247, 184, 1, 1);
      }
    }

    &--4 {

      & .table-3__indicator-select-icon {
        background: rgba(146, 72, 214, 0.15);
      }
    }

    &--4.table-3__indicator-select--current {
      & .table-3__indicator-select-icon,
      &::after {
        background: rgba(146, 72, 214, 1);
      }
    }
  }

  &__indicator-select-icon {
    min-width: 44px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__indicator-select-name {
    display: block;
    font-size: 13px;
    @include bold;
    word-wrap: break-word;
    color: $white;
  }
}