.dropdown {
  //width: 268px;
  width: 100%;
  background: none;
  color: $white;
  border: none;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  cursor: pointer;

  &__label {
    width: 200px;
    display: inline-block;
    @include bold;
    font-size: 20px;
    line-height: 27px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -12px;
  }

  &__wrapper {
    width: 100%;
  }

  &__menu {
    width: 300px;
    height: 600px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 65px;
    z-index: 10;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: auto;
    background-color: #22282E;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 15px;
    line-height: 20px;
    padding: 20px;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $dark-grey;
    }

    & .element-list__category-marker {
      min-width: 12px;
      width: 12px;
      height: 12px;
    }

    &--disabled {
      cursor: default;
      color: rgba($white, 0.5);

      img {
        min-width: 14px;
        width: 14px;
        height: 14px;
      }
    }
  }
}