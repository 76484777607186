.language-select {

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  &__reset-button {
    position: absolute;
    top: 50%;
    right: 15px;

    width: 32px;
    height: 32px;
    margin-top: -16px;

    background: url('../../images/close-icon.svg') no-repeat center;
    background-size: 14px 14px;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__language-item {
    cursor: pointer;
  }
}