.header {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__logo {
    display: flex;
    flex-direction: column;
  }

  &__periodic-table {
    font-weight: 600;
    font-size: 21px;
    color: $white;
    margin: 0;
    line-height: 32px;
  }

  &__version {
    margin: 0;
    @include regular;
    font-size: 14px;
    color: #FCA311;
  }

  &__property-button-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__reset-button {
    cursor: pointer;
    background-color: $red;
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 20px $red;
    border-radius: 6px;
    background-image: url('../../images/close-icon.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
    border: none;

  }
}

@media ($desktop-1360) {
  .header {
    padding-top: 30px;
  }
}

@media ($desktop-1680) {
  .header {

    &__periodic-table {
      font-size: 28px;
    }

    &__version {
      font-size: 18px;
    }
  }
}