.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110px;
  height: 110px;
  animation: pulse 2s ease-in-out infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  transform: translateY(-50%) translateX(-50%);

  &--B {
    background-color: $cyan;
    opacity: 1;
  }

  &--G {
    background-color: $yellow;
    opacity: 1;
  }

  &--A {
    background-color: $red;
    opacity: 1;
  }

  &--K {
    background-color: $dark-green;
    opacity: 1;
  }

  &--H {
    background-color: $green;
    opacity: 1;
  }

  &--C {
    background-color: $orange;
    opacity: 1;
  }

  &--I {
    background-color: $violet;
    opacity: 1;
  }

  &--L {
    background-color: $white;
    color: $black;
    opacity: 1;
  }

  &--D {
    background-color: $blue;
    opacity: 1;
  }

  &--J {
    background-color: $brown;
    opacity: 1;
  }

  &--E {
    background-color: $dark-red;
    opacity: 1;
  }

  &__element {
    width: 95px;
    height: 95px;
    border-radius: 11px;
    background-color: rgba(#fff, 0.35);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  &__symbol {
    font-size: 48px;
    line-height: 48px;
    @include bold;
  }

  &__name {
    font-size: 14px;
  }

  &--small {
    width: 90px;
    height: 90px;

    .loader__element {
      width: 75px;
      height: 75px;
    }

    .loader__symbol {
      font-size: 42px;
      line-height: 42px;
    }

    .loader__name {
      font-size: 12px;
    }
  }
}


@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}