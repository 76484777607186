.main-menu {
  width: 128px;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media ($desktop-2560) {
    width: 160px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 30px;
    top: 50%;
    margin-top: -219px;
  }
}