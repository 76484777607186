.page-main {
  display: flex;

  &__wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ($desktop-1440) {
      justify-content: center;
    }
  }
}