.categories {
  &__wrapper {
    display: flex;
    justify-content: space-around;
    padding-right: 10px;
    padding-left: 10px;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 33px;

    font-weight: 400;
    font-size: 11px;

    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__label {
    @include regular;
    font-size: 12px;
  }

  &__input {
    &:focus + .categories__radio {
      box-shadow: 0 0 0 2px $black, 0 0 0 4px $light-grey;
    }
  }

  &__radio {
    position: absolute;
    left: 0;

    width: 14px;
    height: 14px;

    border: 2px solid $white;
    border-radius: 50%;
  }
}

.B-radio {
  border-color: $cyan;
}

.B-input:checked + .B-radio,
.B-input:focus + .B-radio {
  background-color: $cyan;
}

.A-radio {
  border-color: $red;
}

.A-input:checked + .A-radio,
.A-input:focus + .A-radio {
  background-color: $red;
}

.G-radio {
  border-color: $yellow;
}

.G-input:checked + .G-radio,
.G-input:focus + .G-radio {
  background-color: $yellow;
}

.K-radio {
  border-color: $dark-green;
}

.K-input:checked + .K-radio,
.K-input:focus + .K-radio {
  background-color: $dark-green;
}

.C-radio {
  border-color: $orange;
}

.C-input:checked + .C-radio,
.C-input:focus + .C-radio {
  background-color: $orange;
}

.H-radio {
  border-color: $green;
}

.H-input:checked + .H-radio,
.H-input:focus + .H-radio {
  background-color: $green;
}

.I-radio {
  border-color: $violet;
}

.I-input:checked + .I-radio,
.I-input:focus + .I-radio {
  background-color: $violet;
}

.L-input:checked + .L-radio,
.L-input:focus + .L-radio {
  background-color: $white;
}

.D-radio {
  border-color: $blue;
}

.D-input:checked + .D-radio,
.D-input:focus + .D-radio {
  background-color: $blue;
}

.J-radio {
  border-color: $brown;
}

.J-input:checked + .J-radio,
.J-input:focus + .J-radio {
  background-color: $brown;
}

.E-radio {
  border-color: $dark-red;
}

.E-input:checked + .E-radio,
.E-input:focus + .E-radio {
  background-color: $dark-red;
}

.all-radio {
  background-image: url('../../images/all-elements-radio.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border: none;
}

.all-input:checked + .all-radio {
  background-image: url('../../images/all-elements-selected.svg');
}

.I-input:checked + .I--selected {
  border-color: $dark-grey;
}


@media ($desktop-1440) {
  .categories {
    &__label {
      font-size: 14px;
    }
  }
}

@media ($desktop-1680) {
  .categories {
    &__wrapper {
      padding-right: 20px;
      padding-left: 20px;
    }

    &__item {
      margin-bottom: 15px;
      padding-left: 35px;
    }

    &__label {
      font-size: 16px;
    }

    &__radio {
      width: 20px;
      height: 20px;
    }
  }
}

@media ($desktop-2560) {
  .categories {
    padding-right: 30px;
    padding-left: 30px;

    &__label {
      font-size: 20px;
    }
  }
}
