.table-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: show-language-select 0.3s forwards ease-out;

  &__modal {
    width: 1010px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
    padding: 20px;
  }

  &__content {
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
  }

  &__title {
    margin: 0;
    font-size: 20px;

    @include bold;
  }

  &__close-button {
    display: inline-block;
    width: 44px;
    height: 44px;

    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
    transform: rotate(180deg);
  }

  &__image {
    width: 100%;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &__description {
    font-size: 16px;
    line-height: 120%;
    position: absolute;
    margin: 0;
    white-space: pre-wrap;

    &--1 {
      top: 455px;
      left: 302px;
      width: 258px;
    }

    &--2 {
      top: 255px;
      left: 608px;
      width: 360px;
    }
  }
}