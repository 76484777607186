.table-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: show-language-select 0.3s forwards ease-out;

  &__modal {
    display: flex;
    flex-direction: column;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
    width: 722px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
  }

  &__title {
    margin: 0;
    font-size: 20px;
    @include bold;
  }

  &__close-button {
    display: inline-block;
    width: 44px;
    height: 44px;

    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
    transform: rotate(180deg);
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-bottom: 24px;
    padding-top: 24px;
    padding-right: 38px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(6) {
      background-color: #1a1e23;
      border-top: 1px solid $dark-grey;
      border-bottom: 1px solid $dark-grey;
    }

    &--methane {
      .celsius {
        color: $cyan;
      }
    }

    &--ethane {
      .celsius {
        color: $yellow;
      }
    }

    &--propane {
      .celsius {
        color: $dark-red;
      }
    }

    &--butane {
      .celsius {
        color: $green;
      }
    }

    &--isobutane {
      .celsius {
        color: #717886;
      }
    }

    &--pentane {
      .celsius {
        color: $orange;
      }
    }

    &--isopentane {
      .celsius {
        color: $violet;
      }
    }

    &--neopentane {
      .celsius {
        color: $white;
      }
    }
  }

  &__item-name-and-density {
    position: relative;

    display: flex;
    flex-direction: column;
    padding-left: 62px;
    padding-bottom: 10px;
    margin: 0;
    line-height: 17px;
    border-bottom: 1px solid $light-grey;

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      left: 26px;
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-top: -2px;

      border-radius: 50%;
    }

    &--methane::before {
      background-color: $cyan;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $cyan;
    }

    &--ethane::before {
      background-color: $yellow;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $yellow;
    }

    &--propane::before {
      background-color: $dark-red;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $dark-red;
    }

    &--butane::before {
      background-color: $green;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $green;
    }

    &--isobutane::before {
      background-color: #717886;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px #717886;
    }

    &--pentane::before {
      background-color: $orange;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $orange;
    }

    &--isopentane::before {
      background-color: $violet;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $violet;
    }

    &--neopentane::before {
      background-color: $white;
      box-shadow: 0px 0px 0px 3px #1a1e23, 0px 0px 0px 5px $white;
    }
  }

  &__item-name {
    font-size: 14px;
    margin-bottom: 5px;
    @include bold;
  }

  &__item-density {
    font-size: 13px;
    font-weight: 600;
  }

  &__item-melting-and-boiling {
    position: relative;

    display: flex;
    flex-direction: column;
    padding-left: 62px;
    font-size: 13px;
    line-height: 16px;
    margin: 10px 0 0;
    font-weight: 600;

    &::before {
      content: '°C';
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 20px;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -10px;

      @include bold;
    }
  }

  &__item-boiling {
    margin-bottom: 5px;
  }
}

@media ($desktop-2560) {
  .table-4 {

    &__item-name {
      font-size: 16px;
      margin-bottom: 7px;
    }

    &__item-name-and-density {
      padding-bottom: 16px;
    }

    &__item-melting-and-boiling {
      margin: 16px 0 0;
    }

    &__item-density {
      font-size: 14px;
    }

    &__item-boiling {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__item-melting {
      font-size: 14px;
    }

  }
}