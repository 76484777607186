.tables {
  position: fixed;
  top: 0;
  left: 128px;

  width: 350px;
  height: 100%;
  background-color: $black;

  animation: show-left-window 0.3s forwards ease-out;

  &__title {
    margin: 0;
    padding: 33px 15px 25px 15px;
    font-size: 20px;
    @include bold;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__item {
    background-color: #1A1E22;
    border: 1px solid $dark-grey;
    border-radius: 10px 0px 0px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 80px 98px;
    background-position: center right;
    min-height: 100px;

    &--1 {
      background-image: url('../../images/tables/table-1.png');

      @media ($retina) {
        background-image: url('../../images/tables/table-1@2x.png');
      }

      & .tables__item-title {
        border-color: $yellow;
      }
    }

    &--2 {
      background-image: url('../../images/tables/table-2.png');

      @media ($retina) {
        background-image: url('../../images/tables/table-2@2x.png');
      }

      & .tables__item-title {
        border-color: $blue;
      }
    }

    &--3 {
      background-image: url('../../images/tables/table-3.png');

      @media ($retina) {
        background-image: url('../../images/tables/table-3@2x.png');
      }


      & .tables__item-title {
        border-color: $dark-red;
      }
    }

    &--4 {
      background-image: url('../../images/tables/table-4.png');

      @media ($retina) {
        background-image: url('../../images/tables/table-4@2x.png');
      }

      & .tables__item-title {
        border-color: $orange;
      }
    }

    &--5 {
      background-image: url('../../images/tables/table-5.png');

      @media ($retina) {
        background-image: url('../../images/tables/table-5@2x.png');
      }

      & .tables__item-title {
        border-color: $red;
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 15px;
    color: $white;
    text-decoration: none;
  }

  &__item-title {
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 8px;
    @include bold;
    padding-left: 10px;
    border-left: 2px solid;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__item-description {
    font-size: 12px;
    line-height: 16px;

    display: inline-block;
    display: -moz-box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

@media ($desktop-1680) {

  .tables {

    &__item-title {
      font-size: 16px;
    }

    &__item-description {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media ($desktop-1920) {
  .tables {

    &__item-title {
      margin-bottom: 12px;
    }
  }
}

@media ($desktop-2560) {
  .tables {
    left: 160px;

    &__item-description {
      font-size: 13px;
      line-height: 17px;
    }
  }
}