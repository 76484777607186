.navigation {
  position: relative;
  height: 100%;
  padding: 20px 13px;
  background-color: $dark-grey;
  border-radius: 20px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  &__item {
    &--settings {
      border-top: 1px solid rgba($white, 0.2);
      padding-top: 20px;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: relative;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    transition-duration: 0.3s;

    @media ($desktop-2560) {
      width: 72px;
      height: 72px;
      background-size: 34px;
    }

    &:hover {
      background-color: #22282E;
    }

    &:focus {
      background-color: $dark-grey;
      outline: none;
    }

    &:active {
      background-color: $dark-grey;
    }


    &--periodic-table {
      background-image: url('../../images/periodic-table-icon.svg');
    }

    &--search {
      background-image: url('../../images/search-icon.svg');
    }

    &--list {
      background-image: url('../../images/list-icon.svg');
    }

    &--tables {
      background-image: url('../../images/tables-icon.svg');
    }

    &--settings {
      background-image: url('../../images/settings-icon.svg');
    }

    &--current {
      cursor: default;
      background-color: rgba(255, 255, 255, 0.1);

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 6px;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-left: -2px;
      }

      &--pt {
        &::after {
          background-color: $orange;
        }
      }

      &--search {
        &::after {
          background-color: $cyan;
        }
      }

      &--list {
        &::after {
          background-color: $green;
        }
      }

      &--tables {
        &::after {
          background-color: $red;
        }
      }

      &--settings {
        &::after {
          background-color: $cyan;
        }
      }
    }
  }
}
