.table-5 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: show-language-select 0.3s forwards ease-out;

  &__modal {
    width: 890px;
    display: flex;
    flex-direction: column;
    background-color: $black;
    position: relative;
    z-index: 30;
    border: 1px solid $dark-grey;
    border-radius: 10px;
    padding: 20px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 13px;
  }

  &__title {
    margin: 0;
    font-size: 20px;

    @include bold;
  }

  &__close-button {
    display: inline-block;
    width: 44px;
    height: 44px;

    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
    transform: rotate(180deg);
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 153px;
    height: 200px;
    background: $dark-grey;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 6px 8px 15px;

    &--naphthalene {
      .table-5__item-info {
        border-color: #216f9d;
      }

      .table-5__item-symbol {
        background-color: #216f9d;
      }
    }

    &--acenaphthene {
      .table-5__item-info {
        border-color: #c67a21;
      }

      .table-5__item-symbol {
        background-color: #c67a21;
      }
    }

    &--fluorene {
      .table-5__item-info {
        border-color: #ffb600;
      }

      .table-5__item-symbol {
        background-color: #ffb600;
      }
    }

    &--phenanthrene {
      .table-5__item-info {
        border-color: #087e8b;
      }

      .table-5__item-symbol {
        background-color: #087e8b;
      }
    }

    &--anthracene {
      .table-5__item-info {
        border-color: #f26419;
      }

      .table-5__item-symbol {
        background-color: #f26419;
      }
    }

    &--fluoranthene {
      .table-5__item-info {
        border-color: #f9627d;
      }

      .table-5__item-symbol {
        background-color: #f9627d;
      }
    }

    &--pyrene {
      .table-5__item-info {
        border-color: #ef2917;
      }

      .table-5__item-symbol {
        background-color: #ef2917;
      }
    }

    &--benzo-anthracene {
      .table-5__item-info {
        border-color: #f26419;
      }

      .table-5__item-symbol {
        background-color: #f26419;
      }
    }

    &--chrysene {
      .table-5__item-info {
        border-color: #008148;
      }

      .table-5__item-symbol {
        background-color: #008148;
      }
    }

    &--benzo-b-fluoranthene {
      .table-5__item-info {
        border-color: #c6c013;
      }

      .table-5__item-symbol {
        background-color: #c6c013;
      }
    }

    &--benzo-k-fluoranthene {
      .table-5__item-info {
        border-color: #b80c09;
      }

      .table-5__item-symbol {
        background-color: #b80c09;
      }
    }

    &--dibenzo-anthracene {
      .table-5__item-info {
        border-color: #cfbff7;
      }

      .table-5__item-symbol {
        background-color: #cfbff7;
      }
    }

    &--benzo-perylene {
      .table-5__item-info {
        border-color: #ff3a20;
      }

      .table-5__item-symbol {
        background-color: #ff3a20;
      }
    }

    &--indeno-pyrene {
      .table-5__item-info {
        border-color: #dc136c;
      }

      .table-5__item-symbol {
        background-color: #dc136c;
      }
    }
  }

  &__item-image {
    width: 130px;
    height: 130px;
    align-self: center;
  }

  &__item-info {
    display: flex;
    align-items: center;
    gap: 11px;
    margin: 0;
    padding-top: 13px;
    border-top: 1px solid;
  }

  &__item-symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    width: 36px;
    height: 36px;
    font-size: 14px;
    @include bold;
    border-radius: 50%;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;

    overflow: hidden;
    white-space: nowrap;
  }

  &__item-name {
    font-size: 11px;
    @include bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__item-value {
    font-size: 10px;
  }
}

@media (max-width: 1439px) {
  .table-5 {
    &__modal {
      height: 550px;
      padding: 0;
    }

    &__list {
      overflow: auto;
      padding-bottom: 20px;
    }
  }
}

@media ($desktop-2560) {
  .table-5 {

    &__modal {
      width: 1122px;
    }

    &__item {
      width: 200px;
      height: 240px;
      padding: 6px 12px 15px;
    }

    &__item-info {
      padding-top: 24px;
    }

    &__item-image {
      margin: 8px 0;
    }

    &__item-name {
      font-size: 16px;
      line-height: 22px;
    }

    &__item-value {
      font-size: 14px;
    }
  }
}
