@mixin regular() {
  font-weight: 400;
  font-family: "OpenSans", "Arial", sans-serif;
}

@mixin bold() {
  font-weight: 700;
  font-family: "OpenSans", "Arial", sans-serif;
}

@mixin roboto-regular() {
  font-weight: 400;
  font-family: "RobotoCondensed", "Arial", sans-serif;
}

@mixin roboto-medium() {
  font-weight: 500;
  font-family: "RobotoCondensed", "Arial", sans-serif;
}

@mixin roboto-medium-2() {
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

@mixin roboto-extra-bold() {
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

@mixin container() {
  width: 1200px;
  margin: 0 auto;
}