.read-element {
  position: fixed;
  top: 0;
  right: 0;

  width: 33%;
  max-width: 721px;
  height: 100%;

  background-color: $black;
  border-left: 1px solid $light-grey;

  animation: show-right-window 200ms forwards ease-out;

  &__loader-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__container {
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(
                    180deg,
                    rgba(180, 180, 180, 0.0001) 0%,
                    rgba(0, 0, 0, 0.2) 100%
    ), rgba(46, 46, 46, 0.2);;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 25px;
  }

  &__small-header {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 20;
    transition: top 0.3s ease-in-out;
    background-color: $dark-grey;

    &.visible {
      top: 0;
    }

    &.hidden {
      top: -150px;
    }
  }

  &__small-header-wrapper {
    display: flex;
    align-items: center;
  }

  &__small-element-name {
    @include bold;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 2px;
  }

  &__small-element-weight {
    font-size: 14px;
  }

  &__small-wiki-link {
    display: inline-block;
    width: 44px;
    height: 44px;
    background: url('../../images/wiki-icon.svg') no-repeat center;
    background-size: 24px 24px;
  }

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;

    &--dark {
      .read-element__go-back-btn {
        background-image: url('../../images/arrow-right-dark.svg');
      }

      .read-element__wiki {
        background-image: url('../../images/wiki-icon-dark.svg');
      }

      .read-element__compare-btn {
        background-image: url('../../images/compare-icon-dark.svg');
      }
    }
  }

  &__go-back-btn,
  &__wiki,
  &__compare-btn {
    display: inline-block;
    cursor: pointer;
    border: none;
    width: 44px;
    height: 44px;
    padding: 0;

    &--locked {
      opacity: 0.7;
    }
  }

  &__go-back-btn {
    transform: rotate(180deg);
    background: url('../../images/arrow-right.svg') no-repeat center;
    background-size: 20px 20px;
  }

  &__wiki {
    background: url('../../images/wiki-icon.svg') no-repeat center;
    background-size: 24px 24px;
  }

  &__compare-btn {
    background: url('../../images/compare-icon.svg') no-repeat center;
    background-size: 24px 24px;
  }

  &__groups {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__radioactive {
    display: inline-block;
    margin-bottom: 8px;
    padding: 12px 20px;

    font-size: 12px;
    color: $white;
    text-transform: uppercase;

    background-color: #1A1E22;
    border-radius: 4px 0px 0px 4px;

    @include bold;
  }

  &__selected-element {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
  }

  &__symbol-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;
  }

  &__symbol {
    display: inline-block;
    font-size: 50px;
    @include bold;
    margin-right: 16px;

    @media ($desktop-1680) {
      font-size: 72px;
    }
  }

  &__number {
    @include bold;
    font-size: 17px;
    line-height: 38px;

    @media ($desktop-1680) {
      font-size: 19px;
    }
  }

  &__name {
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
  }

  &__weight {
    font-size: 20px;
    margin-top: -8px;
    @include bold;

    span {
      font-size: 14px;
      @include regular;
    }
  }

  &__element-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__buttons {
    display: flex;
    border-bottom: 1px solid #272A31;
  }

  &__element-link {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 22px 15px;

    font-size: 12px;
    color: $white;
    text-transform: uppercase;
    text-decoration: none;

    transition-duration: 0.4s;
    @include bold;

    &--prev-plug {
      width: 50%;
    }

    &:hover {
      background-color: $light-grey;
    }

    &--prev::before {
      content: "";
      position: absolute;
      left: 15px;

      width: 26px;
      height: 26px;

      background-image: url("../../images/prev-icon.svg");
      background-repeat: no-repeat;
      background-size: 26px 26px;
    }

    &--prev::after {
      content: '';
      position: absolute;
      top: 20px;
      right: 0;
      z-index: -1;

      width: 1px;
      height: 30px;

      background-color: $light-grey;
      border-radius: 2px;
    }

    &--next::before {
      content: '';
      position: absolute;
      top: 20px;
      left: 0;
      z-index: -1;

      width: 1px;
      height: 30px;

      background-color: $light-grey;
      border-radius: 2px;
    }

    &--next::after {
      content: "";
      position: absolute;
      right: 15px;

      width: 26px;
      height: 26px;

      background-image: url("../../images/next-icon.svg");
      background-repeat: no-repeat;
      background-size: 26px 26px;
    }
  }

  &__button-element-number {
    margin-right: 9px;
  }

  &__button-element-name {
    margin-left: 9px;
  }

  &__props-header {
    position: relative;
    display: inline-block;
    padding: 12px 21px 12px 60px;
    font-size: 14px;
    border-radius: 0px 4px 4px 0px;

    margin: 18px 0;
    @include bold;

    &--overview {
      background-color: #f6511d;
    }

    &--properties {
      background-color: #00a878;
    }

    &--termodynamic-props {
      background-color: #3f84e5;
    }

    &--atomic-props {
      background-color: #ce2d4f;
    }

    &--reactivity {
      background-color: #53a548;
    }

    &--electromagnetic-props {
      background-color: #258EA6;
    }

    &--grid-parameters {
      background-color: #ce2d4f;
    }

    &--additional-info {
      background-color: #E94F37;
    }

    &--nuclear-props {
      background-color: #CE2D4F;
    }

    &--prevalence {
      background-color: #0e63a5;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 24px;

      width: 20px;
      height: 18px;
      margin-top: -9px;

      background-repeat: no-repeat;
      background-size: 20px 20px;
    }

    &--overview {
      &::before {
        background-image: url('../../images/atom-icon.svg');
        background-size: 20px 18px;
      }
    }

    &--properties {
      &::before {
        margin-top: -10px;

        background-image: url('../../images/properties-icon.svg');
      }
    }

    &--termodynamic-props {
      &::before {
        background-image: url('../../images/tsMain.svg');
      }
    }

    &--atomic-props {
      &::before {
        background-image: url('../../images/atomic-props-icon.svg');
      }
    }

    &--reactivity {
      &::before {
        background-image: url('../../images/reactivity-icon.svg');
      }
    }

    &--electromagnetic-props {
      &::before {
        background-image: url('../../images/electromagnetic-props-icon.svg');
      }
    }

    &--grid-parameters {
      &::before {
        background-image: url('../../images/grid-parameters-icon.svg');
      }
    }

    &--additional-info {
      &::before {
        background-image: url('../../images/additional-info-icon.svg');
      }
    }

    &--nuclear-props {
      &::before {
        background-image: url('../../images/nuclear-props-icon.svg');
      }
    }

    &--prevalence {
      &::before {
        background-image: url('../../images/prevalence-icon.svg');
      }
    }
  }

  &__props-wrapper {
    padding: 0 38px;

    &--2-column {
      display: flex;
      padding: 0 38px;
    }
  }

  &__props-2-column-wrapper {
    display: flex;
    align-items: center;
  }

  &__props-list {
    &--2-column {
      flex-grow: 1;
      margin-right: 30px;
    }
  }

  &__props-item {
    padding: 11px 0;
    border-bottom: 1px solid $light-grey;

    @include regular;

    &--empty {
      padding: 13px 0;

      font-size: 13px;
      line-height: 15px;

      border-bottom: 1px solid $light-grey;
      opacity: 0.4;
      @include regular;
    }

    &--locked {
      cursor: pointer;
      border-bottom: 1px solid $light-grey;
      @include regular;
      line-height: 15px;
      padding: 13px 0;

      span {
        opacity: 0.5;
      }
    }

    &--nfpa {
      padding-left: 30px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      &--red {
        &::before {
          background-color: #CE2D4F;
        }
      }

      &--blue {
        &::before {
          background-color: #218BCA;
        }
      }

      &--yellow {
        &::before {
          background-color: #F7B801;
        }
      }

      &--white {
        &::before {
          background-color: $white;
        }
      }
    }
  }

  &__props-with-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
  }

  &__props-key {
    display: inline-block;
    margin-bottom: 3px;
    font-size: var(--read-element-font-size);
    @include bold;
  }

  &__props-value {
    font-size: var(--read-element-font-size);
    @include regular;

    .celsius {
      color: $blue;
      @include bold;
    }

    .fahrenheit {
      color: $orange;
      @include bold;
    }

    .kelvin {
      color: $green;
      @include bold;
    }
  }

  &__color-wrapper {
    width: 100%;
  }

  &__element-color {
    width: 100%;
    height: 20px;
    border-radius: 8px;
    margin-top: 6px;
  }

  &__locked-value {
    margin-top: 11px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__property-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--ts0 {
      background-color: #FCBA04;
    }

    &--ts1 {
      background-color: #E94F37;
    }

    &--ts2 {
      background-color: #3F84E5;
    }
  }


  &__oxidation-states-list {
    display: flex;

    &--top {
      margin-top: 10px;
    }
  }

  &__anc-table {
    width: 100%;
    margin: 14px 0;
    text-align: center;
    justify-content: space-between;
    display: flex;
    //gap: 10px;
  }

  &__anc-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__anc-table-key {
    text-transform: uppercase;
    @include bold;
    font-size: 12px;
    padding: 10px 15px;
    border-radius: 5px;

    &--electron {
      background-color: #F71735;
    }


    &--proton {
      background-color: #E59500;
    }

    &--neutron {
      background-color: #3066BE;
    }
  }

  &__anc-table-value {
    font-size: 40px;
    line-height: 54px;
    @include regular;
    font-weight: 800;

    &--electron {
      color: #F71735;
    }


    &--proton {
      color: #E59500;
    }

    &--neutron {
      color: #3066BE;
    }
  }

  &__crystal-grid-image {
    height: 195px;
  }

  &__spectrum-image {
    width: 100%;
    margin-top: 10px;
  }

  &__diamond {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px;
    min-width: 140px;
    width: 140px;
    height: 140px;
    transform: rotate(45deg);
    border: 5px solid #272A31;
    border-radius: 10px;
    position: relative;
  }

  &__diamond-item {
    width: 57px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include bold;
    font-size: 30px;
    border-radius: 5px;

    span {
      transform: rotate(-45deg);
    }

    &--red {
      background-color: #CE2D4F;
    }

    &--yellow {
      background-color: #F7B801;
    }

    &--white {
      background-color: $white;
      color: $black;
    }

    &--blue {
      background-color: #218BCA;
    }
  }

  &__diamond-symbol {
    @include bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 14px;
    padding: 5px;
    border-radius: 50%;
    background-color: #1C1F26;
    border: 3px solid #272A31;
  }
}

@media ($desktop-1680) {
  .read-element {

    &__anc-table-key {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
}

@media ($desktop-1920) {
  .read-element {

    &__anc-table {
      justify-content: space-around;
    }

    &__anc-table-key {
      font-size: 15px;
      padding: 12px 25px;
    }
  }
}