@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "OpenSans";

  src: url("../fonts/OpenSans-Regular.woff2") format("woff2"),
  url("../fonts/OpenSans-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "OpenSans";

  src: url("../fonts/OpenSans-Bold.woff2") format("woff2"),
  url("../fonts/OpenSans-Bold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "RobotoCondensed";

  src: url("../fonts/RobotoCondensed-Regular.woff2") format("woff2"),
  url("../fonts/RobotoCondensed-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "RobotoCondensed";

  src: url("../fonts/RobotoCondensed-Medium.woff2") format("woff2"),
  url("../fonts/RobotoCondensed-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
  url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 800;
  font-family: "Roboto";

  src: url("../fonts/Roboto-ExtraBold.woff2") format("woff2"),
  url("../fonts/Roboto-ExtraBold.woff") format("woff");
}


small {
  font-size: 12px;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  color: $white;
  background-color: $black;
  @include regular;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-thumb {
  background-color: #1A1E23;
  border: 3px solid #252A30;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #252A30;
  border-radius: 8px;
  opacity: 0.5;
}

.page {
  &--start {
    &::before {
      content: '';
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(180deg, #21262C 0%, rgba(33, 38, 44, 0) 100%);
    }
  }

  &--end {
    &::after {
      content: '';
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 42px;
      background: linear-gradient(180deg, rgba(33, 38, 44, 0) 0%, #21262C 100%);
    }
  }
}

.copyright {
  margin: 0 0 34px;
  font-size: 14px;
  line-height: 19px;
  padding-left: 20px;
  color: $silver;

  &__author-link {
    color: #f7b116;
    text-decoration: none;
  }

  &__developer-link {
    color: #35c6b0;
    text-decoration: none;
  }
}


@keyframes show-background {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.75;
  }
}

@keyframes hide-background {
  0% {
    opacity: 0.75;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show-right-window {
  0% {
    transform: translateX(300px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes show-language-select {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-right-window {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes show-left-window {
  0% {
    transform: translateX(-200px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes hide-left-window {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

:root {
  --read-element-font-size: 14px;
}
