.periodic-table {
  border-spacing: 1px 0;
  table-layout: fixed;

  &__table-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 24px;
  }

  &__column-number {
    @include bold;
    padding-bottom: 12px;
    font-size: 11px;
    line-height: 11px;
    vertical-align: bottom;
    text-align: center;
    color: rgba(255, 255, 255, 0.4);
  }

  &__row-number {
    @include bold;
    padding-right: 12px;
    font-size: 11px;
    line-height: 11px;
    vertical-align: middle;
    text-align: right;
    color: rgba(255, 255, 255, 0.4);
  }

  &__transfer {
    padding-top: 3px;
    padding-left: 3px;
    @include roboto-medium;
    font-size: 12px;
    line-height: 14px;
    vertical-align: top;
    text-align: left;

    &-symbols {
      font-size: 9px;
      @include roboto-regular;
    }
  }
}

@media ($desktop-1360) {
  .periodic-table {

    &__container {
      gap: 48px;
    }

    &__column-number {
      padding-bottom: 15px;
      font-size: 12px;
      line-height: 12px;
    }

    &__row-number {
      padding-right: 15px;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

@media ($desktop-1440) {
  .periodic-table {
    &__column-number {
      font-size: 14px;
      line-height: 14px;
    }

    &__row-number {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

@media ($desktop-1680) {
  .periodic-table {
    border-spacing: 3px 0;

    &__column-number {
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 16px;
    }

    &__row-number {
      padding-right: 20px;
      font-size: 16px;
      line-height: 16px;
    }

    &__transfer {
      padding-top: 5px;
      padding-left: 5px;
      font-size: 16px;
      line-height: 20px;

      &-symbols {
        font-size: 14px;
      }
    }
  }
}

@media ($desktop-2560) {
  .periodic-table {
    &__column-number {
      padding-bottom: 24px;

      font-size: 20px;
      line-height: 20px;
    }

    &__row-number {
      padding-right: 24px;

      font-size: 20px;
      line-height: 20px;
    }

    &__transfer {
      padding-top: 10px;
      padding-left: 10px;
      font-size: 18px;
      line-height: 22px;

      &-symbols {
        font-size: 16px;
      }
    }
  }
}
