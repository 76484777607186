.element {
  padding: 0;
  border-bottom: 2px solid #6f7887;
  border-radius: 1px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in-out;

  &__link {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 60px;
    height: 47px;
    padding: 3px;
    color: $white;
    text-decoration: none;

    transition: 300ms ease-in-out;

    &:hover {
      background-color: $dark-grey;
    }

    &:focus {
      background-color: $dark-grey;
      outline: none;
    }
  }

  &__legend-cell {
    vertical-align: bottom;
  }

  &__legend {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85px;
    height: 85px;
    padding: 10px;
    border-radius: 6px;
    background-color: $dark-grey;
    color: $white;
    text-decoration: none;
    overflow: hidden;
    text-align: left;

    & .element__number {
      @include regular;
      font-size: 9px;
      line-height: 9px;
      opacity: 0.8;
    }

    & .element__symbol {
      @include bold;
      font-size: 11px;
      line-height: 11px;
      opacity: 0.8;
    }

    & .element__name {
      @include regular;
      font-size: 9px;
      line-height: 9px;
    }
  }

  &__number {
    font-size: 11px;
    line-height: 11px;
    text-align: left;
    @include roboto-medium;
  }

  &__symbol {
    font-size: 18px;
    line-height: 18px;
    @include bold;
    margin-bottom: 1px;
  }

  &__name {
    overflow: hidden;
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include roboto-regular;
  }
}

.radioactive {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 4px;
  height: 4px;
  background-color: $white;
  border-radius: 50%;

  @media (min-width: 1680px) {
    width: 5px;
    height: 5px;
  }

  @media (min-width: 2560px) {
    width: 7px;
    height: 7px;
  }
}

.B--selected { //B
  border-color: $cyan;
  opacity: 1;
}

.G--selected { //G
  border-color: $yellow;
  opacity: 1;
}

.A--selected { //A
  border-color: $red;
  opacity: 1;
}

.K--selected { //K
  border-color: $dark-green;
  opacity: 1;
}

.H--selected { //H
  border-color: $green;
  opacity: 1;
}

.C--selected { //C
  border-color: $orange;
  opacity: 1;
}

.I--selected { //I
  border-color: $violet;
  opacity: 1;
}

.L--selected { //L
  border-color: $white;
  opacity: 1;
}

.D--selected { //D
  border-color: $blue;
  opacity: 1;
}

.J--selected { //J
  border-color: $brown;
  opacity: 1;
}

.E--selected { //E
  border-color: $dark-red;
  opacity: 1;
}

@media ($desktop-1360) {
  .element {
    &__link {
      width: 65px;
      height: 51px;
    }

    &__legend {
      width: 92px;
      height: 92px;

      & .element__number {
        font-size: 10px;
        line-height: 10px;
      }

      & .element__symbol {
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 1px;
      }

      & .element__name {
        font-size: 10px;
        line-height: 10px;
      }
    }

    &__number {
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 2px;
    }

    &__name {
      font-size: 11px;
      line-height: 11px;
    }
  }
}

@media ($desktop-1440) {
  .element {
    &__link {
      width: 68px;
      height: 54px;
    }

    &__legend {
      width: 100px;
      height: 100px;

      & .element__number {
        font-size: 11px;
        line-height: 11px;
      }

      & .element__symbol {
        font-size: 14px;
        line-height: 14px;
      }

      & .element__name {
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__number {
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 4px;
    }

    &__symbol {
      font-size: 21px;
      line-height: 21px;
      margin-bottom: 3px;
    }

    &__name {
      margin-top: -1px;
      font-size: 11px;
      line-height: 11px;
    }
  }
}

@media ($desktop-1680) {
  .element {
    &__link {
      width: 78px;
      height: 69px;
      padding: 5px;
    }

    &__legend {
      width: 114px;
      height: 114px;


      & .element__number {
        font-size: 13px;
        line-height: 13px;
      }

      & .element__symbol {
        font-size: 17px;
        line-height: 17px;
        margin-bottom: 0;
      }

      & .element__name {
        font-size: 13px;
        line-height: 13px;
      }
    }

    &__number {
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 5px;
    }

    &__name {
      font-size: 13px;
      line-height: 13px;
      margin: 0;
    }
  }
}


@media ($desktop-1920) {
  .element {
    &__link {
      width: 92px;
      height: 73px;
      padding: 5px;
    }

    &__legend {
      width: 120px;
      height: 120px;
    }

    &__number {
      font-size: 15px;
      line-height: 15px;
      margin-bottom: 2px;
    }

    &__symbol {
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 3px;
    }

    &__name {
      font-size: 15px;
      line-height: 15px;
    }
  }
}

@media ($desktop-2560) {
  .element {
    &__link {
      width: 124px;
      height: 96px;
      padding: 8px;
    }

    &__legend {
      width: 170px;
      height: 170px;
      padding: 14px;

      & .element__number {
        font-size: 18px;
        line-height: 18px;
      }

      & .element__symbol {
        font-size: 27px;
        line-height: 27px;
      }

      & .element__name {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &__number {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 3px;
    }

    &__symbol {
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 5px;
    }

    &__name {
      font-size: 19px;
      line-height: 19px;
    }
  }
}
