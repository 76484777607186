.cookie-notification {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 158px;
  z-index: 5;
  background: linear-gradient(180deg, rgba(13, 15, 17, 0) 0%, rgba(13, 15, 17, 0.6) 100%);

  &__modal-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__modal-content {
    position: relative;
    padding: 16px 24px 16px 74px;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
      background-image: url('../../images/cookie-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }

  &__modal {
    width: 740px;
    border-radius: 20px;
    background-color: $dark-grey;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    @include bold;
    font-size: 20px;
    margin: 0 0 10px;
    color: $white;
  }

  &__text-wrapper {
    display: flex;
    gap: 10px;
  }

  &__text {
    color: $white;
    margin: 0;
    font-size: 14px;
  }

  &__link {
    color: $yellow;
  }

  &__button {
    @include bold;
    background-color: $white;
    border: none;
    border-radius: 10px;
    color: $dark-grey;
    font-size: 14px;
    padding: 10px 15px 10px 41px;
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      background-image: url('../../images/okay-icon.svg');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }
}