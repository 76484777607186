.mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: $black;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #1A4F5E 0%, #195B39 100%);

    //filter: blur(50px);

  }

  &__phone {
    position: absolute;
    top: 166px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 60px);
    height: 100%;
    border-radius: 30px;
    z-index: 2;

    &-wrapper {
      padding: 30px;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background: rgba(0, 0, 0, 0.3);
      z-index: 2;


      &::before {
        content: '';
        position: absolute;
        top: 30%;
        left: -45px;
        width: 80px;
        height: 80px;
        background: url('../../images/mobile-vector-1.svg') no-repeat center;
        background-size: 100%;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        top: 15%;
        right: -45px;
        width: 100px;
        height: 100px;
        background: url('../../images/mobile-vector-3.svg') no-repeat center;
        background-size: 100%;
        z-index: -1;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 30px;
  }

  &__pt-label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }

  &__app-logo {
    width: 70px;
    margin-right: 16px;
  }

  &__app-name {
    font-size: 26px;
    position: relative;

    @include roboto-medium-2;

    span:first-child {
      font-size: 36px;
      @include roboto-extra-bold;
    }

    &::before {
      content: '';
      position: absolute;
      top: 30px;
      left: -148px;
      width: 129px;
      height: 120px;
      background: url('../../images/mobile-vector-2.svg') no-repeat center;
      background-size: contain;
      z-index: -1;
    }
  }

  &__apps {
    width: 100%;
    padding: 25px;

    background-color: $black;
    border-radius: 30px 30px 0 0;
    position: relative;
    z-index: 2;
  }

  &__apps-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  &__title {
    @include bold;
    margin: 0;
    font-size: 20px;

    span {
      color: $cyan;
    }
  }

  &__description {
    margin: 0;
    max-width: 400px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  &__link {
    color: $cyan;

    @include bold;
  }

  &__app-store-link {
    display: inline-block;
    width: auto;
    margin: auto 0;
    overflow: hidden;
  }

  &__google-play-link {
    display: inline-block;
    width: 160px;
    overflow: hidden;
  }

  &__store-links {
    display: flex;
    justify-content: center;
  }
}
