.electron-shell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin-top: 14px;

  &__wrapper {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: rotate(90deg);
  }

  &__center {
    position: relative;
    top: 50%;
    left: 50%;

    width: 14px;
    height: 14px;
    margin-right: -2px;

    background-color: $white;
    border-radius: 50%;
  }

  &__circle {
    position: relative;
    top: 50%;
    left: 50%;

    padding: 4px;

    border: 2px solid $white;
    border-radius: 50%;

    animation-name: rotate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--1 {
      width: 40px;
      height: 40px;
      margin-left: -25px;

      animation-duration: 20s;
    }

    &--2 {
      width: 70px;
      height: 70px;
      margin-left: -55px;

      border-color: rgba(255, 255, 255, 0.8);

      animation-duration: 19s;
    }

    &--3 {
      width: 100px;
      height: 100px;
      margin-left: -85px;

      border-color: rgba(255, 255, 255, 0.6);

      animation-duration: 18s;
    }

    &--4 {
      width: 130px;
      height: 130px;
      margin-left: -115px;

      border-color: rgba(255, 255, 255, 0.5);

      animation-duration: 17s;
    }

    &--5 {
      width: 160px;
      height: 160px;
      margin-left: -145px;

      border-color: rgba(255, 255, 255, 0.4);

      animation-duration: 16s;
    }

    &--6 {
      width: 190px;
      height: 190px;
      margin-left: -175px;

      border-color: rgba(255, 255, 255, 0.3);

      animation-duration: 15s;
    }

    &--7 {
      width: 220px;
      height: 220px;
      margin-left: -205px;

      border-color: rgba(255, 255, 255, 0.2);

      animation-duration: 14s;
    }

    &--8 {
      width: 250px;
      height: 250px;
      margin-left: -235px;

      border-color: rgba(255, 255, 255, 0.2);

      animation-duration: 13s;
    }
  }

  &__atom {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;

    background-color: $white;
    border-radius: 50%;
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
