//colors
$white: #F5F5F5;
$black: #20252B;
$dark-grey: #31343D;
$light-grey: #2a2a37;
$test: #008148;
//element-categories colors
$cyan: #20A4F3;
$yellow: #FAA916;
$red: #CE2D4F;
$dark-green: #52713F;
$green: #43894E;
$light-green: #42b57c;
$orange: #FF8600;
$violet: #662e9b;
$blue: #006da1;
$brown: #D84A05;
$dark-red: #C41E3D;
$silver: #6f7887;

$test: #6f7887;

//breakpoints
$mobile: "max-width: 1020px";
$desktop-1280: "min-width: 1280px";
$desktop-1360: "min-width: 1360px";
$desktop-1440: "min-width: 1400px";
$desktop-1680: "min-width: 1600px";
$desktop-1920: "min-width: 1900px";
$desktop-2560: "min-width: 2500px";
$retina: "min-resolution: 1.5dppx), (min-resolution: 144dpi";
