.qr-modal {
  position: relative;

  &__qr-button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: none;
    background-color: #20A4F3;
    cursor: pointer;
    background-image: url('../../images/qrcode-icon.svg');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: center;
    box-shadow: 1px 1px 10px #20A4F3;
  }

  &__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 780px;
    z-index: 5;
    transform: translateX(-50%) translateY(-50%);
    background-color: #20252B;
    border-radius: 20px;
    animation: show-language-select 0.3s forwards ease-out;
  }

  &__modal-wrapper {
    display: flex;
    position: relative;
  }

  &__header {
    width: 358px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(196.87deg, #FC5811 0%, #FC8612 100%);
    padding: 30px;
    border-radius: 20px 0 0 20px;

    &--success {
      background: linear-gradient(196.87deg, #006E1A 0%, #188640 100%);
    }
  }

  &__header-text {
    text-align: center;
    color: $white;
    margin-bottom: 24px;
  }

  &__title {
    @include bold;
    font-size: 22px;
    line-height: 140%;
    margin: 0 0 6px;
  }

  &__description {
    @include regular;
    font-size: 14px;
    line-height: 140%;
    margin: 0;
  }

  &__cloud-image {
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &__cloud {
    width: 100px;
    height: 100px;
  }

  &__qr-wrapper {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 15px;
    display: flex;
  }

  &__qr-code {
    border-radius: 8px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item-title {
    @include bold;
    font-size: 16px;
    margin: 0 0 6px;
  }

  &__item-description {
    @include regular;
    margin: 0;
    font-size: 14px;
    opacity: 0.7;
  }

  &__item {
    padding: 20px 20px 20px 77px;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid #2E2E39;
    }

    &:last-child .qr-modal__item-description {
        opacity: 1;
    }

    &::before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      background-position: center;
      background-size: 100%;
      background-repeat: no-repeat;
    }

    &:nth-child(1)::before {
      background-image: url('../../images/qr-modal-icon-1.svg');
    }

    &:nth-child(2)::before {
      background-image: url('../../images/qr-modal-icon-2.svg');
    }

    &:nth-child(3)::before {
      background-image: url('../../images/qr-modal-icon-3.svg');
    }

    &:nth-child(4)::before {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $red;
      left: 36px;
    }
  }

  &__close-button-wrapper {
    position: relative;
  }

  &__close-button {
    width: 55px;
    height: 55px;
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background: url("../../images/close-icon.svg") no-repeat center, linear-gradient(225deg, #FC5811 0%, #FCA311 100%);
    z-index: 4;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 20px #FCA311;

    &--success {
      background: url("../../images/close-icon.svg") no-repeat center, linear-gradient(225deg, #006E1A 0%, #28965A 100%);
      box-shadow: 0px 0px 20px #28965A;
    }
  }
}